import MailchimpSubscribe from "react-mailchimp-subscribe";
import {
  REACT_APP_MAILCHIMP_ID,
  REACT_APP_MAILCHIMP_U,
  REACT_APP_MAILCHIMP_URL,
} from "../env";
import Newsletter from "./Newsletter";

const MailChimpForm = () => {
  const postUrl = `${REACT_APP_MAILCHIMP_URL}?u=${REACT_APP_MAILCHIMP_U}$id=${REACT_APP_MAILCHIMP_ID}`;

  return (
    <>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <Newsletter
            status={status}
            message={message}
            onValidated={(FormData) => subscribe(FormData)}
          />
        )}
      />
    </>
  );
};

export default MailChimpForm;
