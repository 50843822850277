import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import mongoDB from "../assets/img/mongodb-icon.svg";
import nodeJs from "../assets/img/nodejs-icon.svg";
import expressJs from "../assets/img/icons8-express-js.svg";
import reactJs from "../assets/img/icons8-react-native.svg";
import redux from "../assets/img/icons8-redux.svg";
import socketIO from "../assets/img/socketio-icon.svg";
import stripe from "../assets/img/stripe-icon.svg";

const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <Container>
        <Row>
          <Col>
            <div className="skill-bx">
              <h2>Skills</h2>
              <p>My Technical Expertise</p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="skill-slider"
              >
                <div className="item">
                  <img src={mongoDB} alt="image" />
                  <h5>Mongo DB</h5>
                </div>
                <div className="item">
                  <img src={nodeJs} alt="image" />
                  <h5>Node.js</h5>
                </div>
                <div className="item">
                  <img src={expressJs} alt="image" />
                  <h5>Express.js</h5>
                </div>
                <div className="item">
                  <img src={reactJs} alt="image" />
                  <h5>React.js</h5>
                </div>
                <div className="item">
                  <img src={redux} alt="image" />
                  <h5>Redux</h5>
                </div>
                <div className="item">
                  <img src={socketIO} alt="image" />
                  <h5>Socket.io</h5>
                </div>
                <div className="item">
                  <img src={stripe} alt="image" />
                  <h5>Stripe</h5>
                </div>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-left"
        src={colorSharp}
        alt="color sharp"
      />
    </section>
  );
};

export default Skills;
