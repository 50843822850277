import React from "react";
import colorSharp2 from "../assets/img/color-sharp2.png";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import TrackVisibility from "react-on-screen";
import petsStore from "../assets/img/petsStore.png";
import authenticationApp from "../assets/img/authenticationApp.png";
import InsuranceCost from "../assets/img/insuranceCost.png";
import bloggingAPP from "../assets/img/blogApp.png";
import socialMedia from "../assets/img/socialMedia.png";
import talker from "../assets/img/talker.png";
import expense from "../assets/img/expense.png";
import quiz from "../assets/img/quiz.png";
import airbnb from "../assets/img/airbnb.png";
import whatsApp from "../assets/img/whatsApp.png";
import gmail from "../assets/img/gmail.png";
import netflix from "../assets/img/netflix.png";

const Project = () => {
  const projects = [
    {
      title: "eCommerce Pets Store",
      description:
        "React.js, Express.js, MongoDB, Redux, Stripe, Node.js, Material UI",
      imgUrl: petsStore,
    },
    {
      title: "Authentication Web APPlication",
      description: "React.js, MongoDB, Node.js, Express.js, Node-Mailer",
      imgUrl: authenticationApp,
    },
    {
      title: "Insurance Cost Predictor",
      description: "Machine Learning Algorithms, Django, React.js",
      imgUrl: InsuranceCost,
    },
    {
      title: "Blogging Web Application",
      description: "React.js, Express.js, MongoDB, Node.js, Material UI",
      imgUrl: bloggingAPP,
    },
    {
      title: "Social Media Web Application",
      description: "React.js, Express.js, MongoDB, Node.js, Material UI",
      imgUrl: socialMedia,
    },
    {
      title: "Talker - Chatbot",
      description:
        "React.js, Express.js, MongoDB, Node.js, Socket.io, Dicebear Api for create AI Avatars, Material UI",
      imgUrl: talker,
    },
    {
      title: "Expanse Management System",
      description: "React.js, Express.js, MongoDB, Node.js, Material UI",
      imgUrl: expense,
    },
    {
      title: "Quiz Web Application",
      description: "React.js, Express.js, MongoDB, Node.js, Material UI",
      imgUrl: quiz,
    },
    {
      title: "Netflix Clone",
      description: "React.js, Express.js, MongoDB, Node.js, Material UI",
      imgUrl: netflix,
    },
    {
      title: "Airbnb Clone",
      description: "React.js, Express.js, MongoDB, Node.js, Material UI",
      imgUrl: airbnb,
    },
    {
      title: "WhatsApp Clone",
      description:
        "React.js, Express.js, MongoDB, Node.js, Material UI, Socket.io",
      imgUrl: whatsApp,
    },
    {
      title: "Gmail Clone",
      description: "React.js, Express.js, MongoDB, Node.js, Material UI",
      imgUrl: gmail,
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__slideInUp" : ""
                  }
                >
                  <h2>Projects</h2>
                  <p>Here Is My Learnings</p>
                </div>
              )}
            </TrackVisibility>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                {/* <Nav.Item>
                  <Nav.Link eventKey="first">Tab One</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Tab Two</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Tab Three</Nav.Link>
                </Nav.Item> */}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {projects.map((project, index) => {
                      return <ProjectCard key={index} {...project} />;
                    })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">loren ipsum</Tab.Pane>
                <Tab.Pane eventKey="third">loren ipsum</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="" />
    </section>
  );
};

export default Project;
